<template>
  <div>
    <div class="mt-6">
      <vs-row vs-w="12">
        <vs-col
          class="pr-4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <div class="w-full">
            <vs-input
              class="inputx w-full mb-2 mt-2"
              placeholder=""
              label="N° client*"
              :value="numClient"
              @change.native="numClient = $event.target.value"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="N° Client"
            />
            <div class="aab-vie-error-msg">{{ errors.first("N° Client") }}</div>
          </div>
        </vs-col>

        <vs-col
          class="pr-4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <div class="w-full">
            <vs-input
              label="Référence contrat *"
              class="inputx w-full mb-2 mt-2"
              placeholder=""
              v-model="form.refContrat"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="Référence contrat"
            />
            <div class="aab-vie-error-msg">
              {{ errors.first("Référence contrat") }}
            </div>
          </div>
        </vs-col>

        <vs-col
          class="pr-4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <div class="w-full">
            <vs-input
              type="date"
              class="inputx w-full mb-2 mt-2"
              label="Date d'effet*"
              v-model="startDateContributions"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="Date d'effet"
            />
            <div class="aab-vie-error-msg">
              {{ errors.first("Date d'effet") }}
            </div>
          </div>
        </vs-col>

        <vs-col
          class="pr-4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <div class="w-full">
            <vs-input
              type="date"
              disabled
              class="inputx w-full mb-2 mt-2"
              label="Date d'échéance*"
              v-model="form.endDateContributions"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="Date d'échéance"
            />
            <div class="aab-vie-error-msg">
              {{ errors.first("Date d'échéance") }}
            </div>
          </div>
        </vs-col>

        <vs-col
          class="pr-4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <div class="w-full">
            <label for="" class="vs-input--label">Durée de couverture*</label>
            <multiselect
              v-model="form.durationCoverage"
              :options="durationCoverageListSelect"
              placeholder="--Choisir--"
              label="label"
              track-by="label"
              v-validate="'required'"
              name="Durée de couverture"
              disabled
              :selectLabel="global.selectLabel"
              :selectedLabel="global.selectedLabel"
              :deselectLabel="global.deselectLabel"
            >
              <template v-slot:noOptions>
                <span>{{ global.selectNotFoundData }}</span>
              </template>
            </multiselect>

            <div class="aab-vie-error-msg">
              {{ errors.first("Durée de couverture") }}
            </div>
          </div>
        </vs-col>

        <vs-col
          class="pr-4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <div class="w-full">
            <vs-input
              class="inputx w-full mb-2 mt-2"
              disabled
              label="Périodicité de paiement"
              name="Périodicité de paiement"
              v-validate="'required'"
              data-vv-validate-on="blur"
              v-model="form.periodicityContributions"
            />
            <div class="aab-vie-error-msg">
              {{ errors.first("Périodicité de paiement") }}
            </div>
          </div>
        </vs-col>

        <vs-col
          class="pr-4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <div class="w-full">
            <vs-input
              disabled
              type="number"
              class="aab-vie-form-number inputx w-full mt-2 mb-2"
              label="Cotisation TTC*"
              v-model="form.cotisationTTC"
              v-validate="'required|numeric'"
              data-vv-validate-on="blur"
              name="Cotisation TTC"
            />
            <div class="aab-vie-error-msg">
              {{ errors.first("Cotisation TTC") }}
            </div>
          </div>
        </vs-col>

        <vs-col
          class="pr-4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-sm="6"
          vs-xs="12"
        >
          <div class="w-full" v-if="!loadingAddClient">
            <span class="pl-2">Compte client*</span>
            <div class="flex justify-between align-items-center">
              <multiselect
                v-model="clientAccount"
                :options="clientAccountList"
                placeholder="--Choisir--"
                label="label"
                track-by="label"
                v-validate="'required'"
                name="Compte client"
                required
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              >
                <template v-slot:noOptions>
                  <span>{{ global.selectNotFoundData }}</span>
                </template>
              </multiselect>
              <vx-tooltip color="primary" text="Ajouter un client">
                <vs-button
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-user-plus"
                  @click="addUser"
                ></vs-button>
              </vx-tooltip>
            </div>

            <div class="aab-vie-error-msg">
              {{ errors.first("Compte client") }}
            </div>
          </div>
          <div v-else class="ml-2">
            <clip-loader
              :loading="loadingAddClient"
              color="primary"
            ></clip-loader>
          </div>
        </vs-col>

        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="mb-6 mt-4"
        >
          <div class="mb-4">
            <span>Objet*</span>
            <multiselect
              v-model="variableGlobalFormOject"
              :options="allMailVariableList"
              class="mb-4 mt-2"
              id="text"
              placeholder="--Insérer une variable de personnalisation--"
              label="label"
              track-by="label"
              :selectLabel="global.selectLabel"
              :selectedLabel="global.selectedLabel"
              :deselectLabel="global.deselectLabel"
            >
            </multiselect>
          </div>

          <vs-input
            class="inputx w-full mb-2 mt-2"
            placeholder=""
            id="texto"
            @change="onEditorChangeFormObjet($event)"
            v-model="form.object"
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="Objet"
          />
          <div class="aab-vie-error-msg">{{ errors.first("Objet") }}</div>
        </vs-col>
        <vs-col
          vs-justify="left"
          vs-align="left"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="mb-6"
        >
          <div class="mb-4">
            <span>Message*</span>
          </div>

          <div class="w-full">
            <multiselect
              v-model="variableGlobalForm"
              :options="allMailVariableList"
              class="mb-4"
              placeholder="--Insérer une variable de personnalisation--"
              label="label"
              track-by="label"
              :selectLabel="global.selectLabel"
              :selectedLabel="global.selectedLabel"
              :deselectLabel="global.deselectLabel"
            >
            </multiselect>

            <quill-editor
              @focus="onEditorFocusForm($event)"
              @change="onEditorChangeForm($event)"
              v-model="form.message"
            >
            </quill-editor>
          </div>
        </vs-col>

        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="mb-6"
        >
          <div
            class="w-full"
            style="
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
            "
          >
            <div>
              <div>
                <div class="mb-4 ml-2">
                  <span>Fichier(s)</span>
                </div>
                <div>
                  <div uk-form-custom class="ebsi-upload-file">
                    <input
                      multiple
                      type="file"
                      :value="resetFilesUpload"
                      v-on:change="checkUploadFile"
                    />
                    <button
                      style="cursor: pointer"
                      class="uk-button uk-button-default"
                      type="button"
                      tabindex="-1"
                    >
                      Joindre des fichiers
                    </button>
                  </div>
                </div>
              </div>

              <div class="aab-vie-error-msg" v-show="formError">
                Erreur lors du chargement du fichier
              </div>
            </div>
          </div>

          <div class="mt-6 mb-3" style="display: flex; flex-direction: column">
            <div
              v-for="(file, index) in form.filesname"
              :key="index"
              class="center"
            >
              <div class="flex">
                {{ index + 1 }})

                <div class="pl-2" v-if="file.filename && file.name">
                  <b class="pl-2 cursor-pointer" @click="checkViewFile(file)">{{
                    subString(file.name)
                  }}</b>
                </div>
                <div class="pl-2" v-else>
                  <b>{{ subString(file) }}</b>
                </div>

                <vx-tooltip color="primary" text="Supprimer">
                  <div class="ml-8 cursor-pointer" @click="removeFile(index)">
                    <feather-icon
                      icon="Trash2Icon"
                      svgClasses="w-6 h-6"
                    ></feather-icon>
                  </div>
                </vx-tooltip>
              </div>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </div>

    <div class="mt-8">
      <div class="inline-flex">
        <div>
          <vs-button
            class="w-full sm:w-auto"
            :disabled="!validateForm || loading"
            @click="fetchApplyEnabledPolice"
            >Valider</vs-button
          >
          <vs-button
            class="w-full sm:w-auto ml-4"
            type="border"
            @click="exitPolice"
            >Annuler</vs-button
          >
        </div>
        <div class="ml-2">
          <clip-loader :loading="loading" color="primary"></clip-loader>
        </div>
      </div>
      <vs-popup
        class="holamundo"
        title="Ajouter un client"
        :active.sync="popupAddClient"
      >
        <div class="m-4">
          <div class="clearfix">
            <div>
              <vs-row vs-w="12">
                <vs-col
                  class="pr-4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2 mt-2"
                      placeholder="Entrer nom client"
                      label="Nom *"
                      v-model="formClient.lastname"
                      v-validate="'required|min:3'"
                      data-vv-validate-on="blur"
                      name="nom"
                    />
                    <div class="aab-vie-error-msg">
                      {{ errors.first("nom") }}
                    </div>
                  </div>
                </vs-col>
                <vs-col
                  class="pr-4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2 mt-2"
                      placeholder="Entrer prénom(s) client "
                      label="Prénom(s) *"
                      v-model="formClient.firstname"
                      v-validate="'required|min:3'"
                      data-vv-validate-on="blur"
                      name="prénom"
                    />
                    <div class="aab-vie-error-msg">
                      {{ errors.first("prénom") }}
                    </div>
                  </div>
                </vs-col>
                <vs-col
                  class="pr-4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2 mt-2"
                      placeholder="Entrer nom d'utilisateur client"
                      label="Nom d'utilisateur (N° client)"
                      v-model="formClient.username"
                      v-validate="'required|min:3'"
                      data-vv-validate-on="blur"
                      name="nom d'utilisateur"
                    />
                    <div class="aab-vie-error-msg">
                      {{ errors.first("nom d'utilisateur") }}
                    </div>
                  </div>
                </vs-col>
                <vs-col
                  class="pr-4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2 mt-2"
                      label="Adresse *"
                      placeholder="Entrer adresse d'utilisateur client"
                      v-model="formClient.adress"
                      v-validate="'required'"
                      data-vv-validate-on="blur"
                      name="Adresse"
                    />
                    <div class="aab-vie-error-msg">
                      {{ errors.first("Adresse") }}
                    </div>
                  </div>
                </vs-col>

                <vs-col
                  class="pr-4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2 mt-2"
                      placeholder="Téléphone *"
                      label="Téléphone"
                      v-model="formClient.phone"
                      v-validate="'required'"
                      data-vv-validate-on="blur"
                      name="Téléphone"
                    />
                    <div class="aab-vie-error-msg">
                      {{ errors.first("Téléphone") }}
                    </div>
                  </div>
                </vs-col>
                <vs-col
                  class="pr-4"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div class="w-full">
                    <vs-input
                      class="inputx w-full mb-2 mt-2"
                      placeholder="Entrer email client"
                      label="Email *"
                      v-model="formClient.email"
                      v-validate="'required|email'"
                      data-vv-validate-on="blur"
                      type="email"
                      name="email"
                    />
                    <div class="aab-vie-error-msg">
                      {{ errors.first("email") }}
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
          <div class="mt-8">
            <div class="inline-flex">
              <div>
                <vs-button
                  class="w-full sm:w-auto"
                  :disabled="!validateFormAddClient || loadingAddClient"
                  @click="fetchApplyAddClient"
                  >Valider</vs-button
                >
                <vs-button
                  class="w-full sm:w-auto ml-4"
                  type="border"
                  @click="popupAddClient = false"
                  >Annuler</vs-button
                >
              </div>
              <div class="ml-2">
                <clip-loader
                  :loading="loadingAddClient"
                  color="primary"
                ></clip-loader>
              </div>
            </div>
            <div class="mt-6">
              <p class="text-danger">* Champ obligatoire</p>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mixin } from "@/mixins/mixin";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import global from "@/config/global";
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";

export default {
  name: "aab-vie-subscribe-enabled-police",
  props: {
    isClo: Boolean,
    crrModuleName: String,
    byProject: Object,
    item: Object,
  },
  data() {
    return {
      cursorPositionObject: 0,
      formTakeOjet: {},
      byProductContent: {},
      variableGlobalFormOject: "",
      startDateContributions: "",
      popupAddClient: false,
      cursorPositionForm: 0,
      clientAccount: "",
      quillEditorForm: {},
      newEnableProject: {},
      variableGlobalForm: "",
      clientAccountList: [],
      durationCoverageListSelect: [],
      form: {
        username: "",
        client: "",
        refContrat: "",
        startDateContributions: "",
        endDateContributions: "",
        durationCoverage: "",
        periodicityContributions: "",
        cotisationTTC: "",
        message: "",
        object:
          "{{Civilité}} {{Nom}}, activation de votre contrat {{Produit}} - {{Ref_Contrat}}",
        filesname: [],
      },
      formClient: {
        email: "",
        firstname: "",
        lastname: "",
      },
      loading: false,
      loadingAddClient: false,
      fileData: [],
      uploadFile: false,
      formData: [],
      fileDataUp: {},
      resetFilesUpload: "",
      displayFileName: false,
      formError: false,
      error: false,
      errorMsg: "",
      isUserScrolling: false,
      loadingDocTempoDeces: false,
      tempo_deces_xls_doc: "",
      cotisationDefaultList: [],
    };
  },
  computed: {
    ...mapGetters("users", ["allUsers"]),
    ...mapGetters("general", [
      "allDocClient",
      "allMailVariableText",
      "allMailVariableList",
      "allPeriodicityOfPayment",
      "allPeriodicityOfPaymentValue",
    ]),
    numClient: {
      // getter
      get: function () {
        return this.form.username;
      },
      // setter
      set: function (newValue) {
        this.clientAccount = this.clientAccountList.find(
          (client) => client.username == newValue
        )
          ? this.clientAccountList.find((client) => client.username == newValue)
          : "";
      },
    },
    validateForm() {
      return (
        !this.errors.any() &&
        this.form.object != "" &&
        this.form.client != "" &&
        this.form.refContrat != "" &&
        this.form.startDateContributions != "" &&
        this.form.endDateContributions != "" &&
        this.form.durationCoverage != "" &&
        this.form.periodicityContributions != "" &&
        this.form.cotisationTTC != "" &&
        this.form.message != ""
      );
    },
    validateFormAddClient() {
      return (
        !this.errors.any() &&
        this.formClient.email != "" &&
        this.formClient.firstname != "" &&
        this.formClient.lastname != "" &&
        this.formClient.username != ""
      );
    },
  },

  mounted() {
    this.fetchApplyGetAllClient({
      limit: "Tout",
      skip: 0,
      type: ["client"],
    });
  },

  created() {
    //add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.global = global;
  },

  components: {
    quillEditor,
    ClipLoader,
    Multiselect,
    vSelect,
  },
  methods: {
    ...mapActions("general", [
      "applyUploadFile",
      "applyGetItemDocument",
      "applyGenerateCertificat",
      "applyGenerateCotisation",
      "applyGenerateCotisationSolde",
    ]),
    ...mapActions("suscribe", [
      "applyEnableProject",
      "applyPutProject",
      "applySentProject",
      "applyPutAvenant",
      "applyPutQuittance",
      "applyAddAllQuittance",
    ]),
    ...mapActions("users", ["applyGetAllUsersSys", "applyAddUserSys"]),
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    exitPolice() {
      this.$emit("onDisplayEnabledPolice", false);
    },
    onPeriodicityOfPayment (period) {
      return this.allPeriodicityOfPayment.find(
        (el) => el.id === period
      ).label
    },
    applyGetDefaultPayment(project) {
      if(project.productName == "ABQ" || project.productName == "APF" || project.productName == "AAF") {
        this.durationCoverageListSelect = [
          { id: 1, label: 1 },
          { id: 2, label: 2 },
          { id: 3, label: 3 },
          { id: 4, label: 4 },
          { id: 5, label: 5 },
          { id: 6, label: 6 },
          { id: 7, label: 7 },
          { id: 8, label: 8 },
          { id: 9, label: 9 },
          { id: 10, label: 10 },
        ]

        this.byProductContent = project.getDefaultAvenant
      }


      if(project.productName == "AAE") {
        this.durationCoverageListSelect = [
          { id: 10, label: 10 },
          { id: 11, label: 11 },
          { id: 12, label: 12 },
          { id: 13, label: 13 },
          { id: 14, label: 14 },
          { id: 15, label: 15 },
          { id: 16, label: 16 },
          { id: 17, label: 17 },
          { id: 18, label: 18 },
          { id: 19, label: 19 },
          { id: 20, label: 20 },
        ]

        this.byProductContent = project.getDefaultQuittance
      }

      if(project.productName == "AHR") {
        this.durationCoverageListSelect = [
          { id: 10, label: 10 },
          { id: 11, label: 11 },
          { id: 12, label: 12 },
          { id: 13, label: 13 },
          { id: 14, label: 14 },
          { id: 15, label: 15 },
        ]

        this.byProductContent = project.getDefaultQuittance
      }
    },
    async fetchTarifsDocContent(data) {
      this.loadingDocTempoDeces = true;

      try {
        let resItemDoc = await this.applyGetItemDocument({
          code: "tarifs_tempo_deces",
          productName: this.allDocClient[this.item.productName],
        });

        this.tempo_deces_xls_doc = resItemDoc.data
          ? resItemDoc.data.filename
          : "";

        this.byGetTarifsContentFile();

        this.loadingDocTempoDeces = false;
        this.error = false;
      } catch (err) {
        this.loadingDocTempoDeces = false;
        this.error = true;
        console.log("err", err);
      }
    },

    byGetTarifsContentFile() {
      mixin.methods
        .getAmountAndCotisationAPF(this.tempo_deces_xls_doc)
        .then((res) => {
          if (res.Cotisation) {
            this.cotisationDefaultList = []
            res.Cotisation.forEach((el) => {
              this.cotisationDefaultList.push({
                id: el,
                label: el,
              });
            });

            console.log(
              "cotisationDefaultList FILE EXCELL",
              this.cotisationDefaultList
            );
          }
        });
    },

    addUser() {
      this.$emit("onDisplayEnabledPolice", false);
      this.popupAddClient = true;
      this.formClient = {
        firstname: this.item.subscription.subscriber.firstname,
        lastname: this.item.subscription.subscriber.lastname,
        adress: this.item.subscription.subscriber.adress,
        phone: this.item.subscription.subscriber.phone,
        email: this.item.subscription.subscriber.email,
      };
    },

    async fetchApplyAddClient() {
      if (!this.validateFormAddClient) return false;
      this.loadingAddClient = true;
      let data = {};

      data = {
        ...this.formClient,
        type: "client",
      };
      try {
        await this.applyAddUserSys(data).then((res) => {
          this.fetchApplyGetAllClient({
            limit: "Tout",
            skip: 0,
            type: ["client"],
          });
        });

        this.loadingAddClient = false;
        this.acceptAlert(
          mixin.methods.message("subscription", "addClient").success
        );

        this.formClient = {
          email: "",
          firstname: "",
          lastname: "",
          username: "",
        };

        this.popupAddClient = false;
      } catch (err) {
        this.loadingAddClient = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddClient = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Le nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "userNameExistError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("subscription", "addClient").error
                  );

                  break;
              }
            });
          }
        }
      }
    },

    async fetchApplyGetAllClient(data) {
      this.loading = true;

      try {
        await this.applyGetAllUsersSys(data).then((res) => {
          this.clientAccountList = this.allUsers.list.map((el) => {
            return { ...el, id: el.id, label: el.identity };
          });
          console.log("applyGetAllUsersSys", this.clientAccountList);
        });

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    /* Form Objet */
    getInputSelection(el) {
      let start = 0,
        end = 0,
        normalizedValue,
        range,
        textInputRange,
        len,
        endRange;

      if (
        typeof el.selectionStart == "number" &&
        typeof el.selectionEnd == "number"
      ) {
        start = el.selectionStart;
        end = el.selectionEnd;
      } else {
        range = document.selection.createRange();

        if (range && range.parentElement() == el) {
          len = el.value.length;
          normalizedValue = el.value.replace(/\r\n/g, "\n");

          textInputRange = el.createTextRange();
          textInputRange.moveToBookmark(range.getBookmark());

          endRange = el.createTextRange();
          endRange.collapse(false);

          if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
            start = end = len;
          } else {
            start = -textInputRange.moveStart("character", -len);
            start += normalizedValue.slice(0, start).split("\n").length - 1;

            if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
              end = len;
            } else {
              end = -textInputRange.moveEnd("character", -len);
              end += normalizedValue.slice(0, end).split("\n").length - 1;
            }
          }
        }
      }

      return {
        start: start,
        end: end,
      };
    },

    onEditorChangeFormObjet(position) {
      let input = document.getElementById("texto");
      input.focus();
      this.cursorPositionObject = this.getInputSelection(input).start;
      this.formTakeOjet = position;
    },
    insertTextFormObjet(data) {
      this.form.object = [
        this.form.object.slice(0, this.cursorPositionObject),
        `${data} `,
        this.form.object.slice(this.cursorPositionObject),
      ].join("");
      this.cursorPositionObject = this.form.object.length;
    },
    /* End Form Objet */
    /* Form*/
    onEditorChangeForm(quill) {
      this.cursorPositionForm = quill.quill.getSelection();
      this.quillEditorForm = quill.quill;
    },
    onEditorFocusForm(quill) {
      this.cursorPositionForm = quill.getSelection();
      this.quillEditorForm = quill;
    },
    insertTextForm(data) {
      this.quillEditorForm.insertText(this.cursorPositionForm, `${data} `);
      this.cursorPositionForm = this.form.message.length - 7;
    },
    /* End Form*/

    async uploadedFiles() {
      let _this = this;
      let productPromises = [];
      this.formData.forEach(function (ele, index) {
        productPromises.push(
          (async function (ele) {
            const res = await _this.applyUploadFile(ele);
            _this.fileData.push({
              filename: res.data[0].uploadedName,
              name: res.data[0].filename,
            });
          })(ele)
        );
      });
      await Promise.all(productPromises);
    },

    async fetchApplyEnabledPolice() {
      if (!this.validateForm) return false;
      this.loading = true;

      let data = {
        ...this.form,
        durationCoverage: this.form.durationCoverage.id,
        client: this.clientAccount.id,
        projectId: this.item.id,
        quote: this.item.quote.id,
        broker: this.item.broker.id,
        startDateContributions: this.form.startDateContributions,
        endDateContributions: this.form.endDateContributions,
        isActivePolice: true,
      }

      try {
        this.newEnableProject = await this.applyEnableProject(data);

        await this.addMail(this.fileData);

        this.formData = [];
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Le nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "userNameExistError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Ajout",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },

    async addMail(fileData) {
      if (this.form.filesname.length > 0) {
        await this.uploadedFiles()
          .then((res) => {
            this.acceptAlert(
              mixin.methods.message("subscription", "file").success
            );

            this.fetchApplySentMailProject(fileData);
          })
          .catch((err) => {
            console.log(err);
            this.acceptAlert(
              mixin.methods.message("subscription", "file").error
            );

          });
      } else {
        this.fetchApplySentMailProject(fileData);
      }
    },

    async fetchApplySentMailProject(fileData) {
      this.loading = true;
      if (!this.validateForm) return false;

      let filesname = []

      if(this.newEnableProject.productName == "ABQ" || this.newEnableProject.productName == "APF" || this.newEnableProject.productName == "AAF") {
        await this.applyPutAvenant({
          avenantId: this.byProductContent.id,
          durationCoverage: this.form.durationCoverage.id,
          startDateContributions: this.form.startDateContributions,
          endDateContributions: this.form.endDateContributions,
          cotisationTTC: this.form.cotisationTTC,
        })

        // Cotisation Solde + Certificat
        let gCotisationSolde = await this.applyGenerateCotisationSolde({
          projectId: this.newEnableProject.id,
          avenantId: this.byProductContent.id,
        });
        let gCertificat = await this.applyGenerateCertificat({
          projectId: this.newEnableProject.id,
          avenantId: this.byProductContent.id,
        });

        filesname = [
          {
            name: gCotisationSolde.data.docClient.name+".pdf",
            filename: gCotisationSolde.data.currPdfName,
          },
          {
            name: gCertificat.data.docClient.name+".pdf",
            filename: gCertificat.data.currPdfName,
          },
          ...fileData,
        ]
        await this.applyPutAvenant({
          avenantId: this.byProductContent.id,
          durationCoverage: this.form.durationCoverage.id,
          startDateContributions: this.form.startDateContributions,
          endDateContributions: this.form.endDateContributions,
          cotisationTTC: this.form.cotisationTTC,
          status: "active",
          filesname,
        })
      }

      if(this.newEnableProject.productName == "AAE" || this.newEnableProject.productName == "AHR") {
        let z = this.newEnableProject.quote.aboutProposition.data.find(
          (el) => el.id == this.newEnableProject.quote.aboutProposition.selectedId
        )

        let periodicityMonth = 0;
        if (this.newEnableProject.productName == "AHR") {
          periodicityMonth = 1; // Périodicité (Mensuelle par défaut) = 1 Mois
        }
        if (this.newEnableProject.productName == "AAE") {
          switch (z.periodicityOfPayment) {
            case "monthly": // Mensuelle
              periodicityMonth = 1

              break;
            case "quarterly": // Trimestrielle
              periodicityMonth = 3

              break;
            case "biannual": // Semestrielle
              periodicityMonth = 6

              break;
            case "annual": // Annuelle
              periodicityMonth = 12

              break;

            default:
              break;
          }
        }
   
        let oldtByProductContent = this.byProductContent

        let newQuittancesList = await this.applyAddAllQuittance({
          projectId: this.newEnableProject.id,
          startDateContributions: this.form.startDateContributions,
          endDateContributions: this.form.endDateContributions,
          accessoir: z.accessoir,
          periodicityMonth,
          cotisationTTC: parseInt(this.form.cotisationTTC) - parseInt(z.accessoir),
          numberOfContributions: this.allPeriodicityOfPaymentValue.find(
            (el) => el.id == (this.newEnableProject.productName == "AHR" ? "monthly" : z.periodicityOfPayment)
          ).value * this.form.durationCoverage.id,
        })

        this.byProductContent = mixin.methods.getDefaultQuittance(newQuittancesList)
        // Cotisation Solde + Certificat
        let gCotisationSolde = await this.applyGenerateCotisationSolde({
          projectId: this.newEnableProject.id,
          quittanceId: this.byProductContent.id,
        })
        let gCertificat = await this.applyGenerateCertificat({
          projectId: this.newEnableProject.id,
          quittanceId: this.byProductContent.id,
        })

        let conditionGeneral, ficheProduit

        if(this.newEnableProject.productName == "AHR") {
          conditionGeneral = "by_condition_general_ahr"
          ficheProduit = "by_fiche_produit_ahr"
        }
        if(this.newEnableProject.productName == "AAE") {
          conditionGeneral = "by_condition_general_aae"
          ficheProduit = "by_fiche_produit_aae"
        }
        // Fetch Condition General Produit
        let resConditionGeneralDoc = await this.applyGetItemDocument({
          code: conditionGeneral,
          productName: this.allDocClient[this.newEnableProject.productName],
        });
        // Fetch Fiche Produit
        let resFicheProductDoc = await this.applyGetItemDocument({
          code: ficheProduit,
          productName: this.allDocClient[this.newEnableProject.productName],
        })

        await this.applyPutProject({
          ...this.newEnableProject,
          filesnameCertificat: gCertificat.data.currPdfName,
          filesname: [
          ...fileData,
          {
            name: gCertificat.data.docClient.name+".pdf",
            filename: gCertificat.data.currPdfName,
          },
          {
            name: resConditionGeneralDoc.data.name + ".pdf",
            filename: resConditionGeneralDoc.data.filename,
          },
          {
            name: resFicheProductDoc.data.name + ".pdf",
            filename: resFicheProductDoc.data.filename,
          }
        ],
          projectId: this.newEnableProject.id,
          quote: this.newEnableProject.quote.id,
          broker: this.newEnableProject.broker.id,
          client: this.clientAccount.id,
        })

        await this.applyPutQuittance({ // Update default new quittance
          quittanceId: this.byProductContent.id,
          ...this.byProductContent,
          paymentRequest: oldtByProductContent.paymentRequest,
          transactionId: oldtByProductContent.transactionId,
          status: oldtByProductContent.status,
          paymentStatus: oldtByProductContent.paymentStatus,
          filesname: [
            {
              name: gCotisationSolde.data.docClient.name+".pdf",
              filename: gCotisationSolde.data.currPdfName,
            }
          ],
        })

        filesname = [
          ...fileData,
          {
            name: gCertificat.data.docClient.name+".pdf",
            filename: gCertificat.data.currPdfName,
          },
          {
            name: gCotisationSolde.data.docClient.name+".pdf",
            filename: gCotisationSolde.data.currPdfName,
          },
          {
            name: resConditionGeneralDoc.data.name + ".pdf",
            filename: resConditionGeneralDoc.data.filename,
          },
          {
            name: resFicheProductDoc.data.name + ".pdf",
            filename: resFicheProductDoc.data.filename,
          }
        ]
      }

      try {
        let dataMail = {
          object: this.form.object,
          message: this.form.message,
          filesname,
          subscriberId: this.newEnableProject.subscription.subscriber.id,
          projectId: this.newEnableProject.id,
          brokerId: this.newEnableProject.broker ? this.newEnableProject.broker.id : "",
        }

        setTimeout(async () => {
          await this.applySentProject(dataMail).then((res) => {
            this.loading = false;
            this.acceptAlert(
              mixin.methods.message("subscription", "enablePolice").success
            );

            this.form = {
              client: "",
              username: "",
              refContrat: "",
              startDateContributions: "",
              endDateContributions: "",
              durationCoverage: "",
              periodicityContributions: "",
              cotisationTTC: "",
              message: "",
              object: "",
              filesname: [],
            }
            this.fileData = [];
            this.clientAccount = "";
            this.newEnableProject = {}
            this.exitPolice();
          })
        }, 1500)

        this.loading = false;
        this.exitPolice();
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";
      }
    },
    //fichier
    checkUploadFile(evt) {
      const evtLength = evt.target.files.length;
      let allFilesChecked = [];
      let formDataLength = this.formData.length;
      for (let i = 0; i < evtLength; i++) {
        let formDataTmp = new FormData();
        formDataTmp.append("uri", evt.target.files[i]);
        this.formData[formDataLength + 1] = formDataTmp;
        this.formError = false;
        allFilesChecked.push(evt.target.files[i].name);
        formDataLength++;
      }
      if (this.form.filesname) {
        this.form.filesname = [...this.form.filesname, ...allFilesChecked];
      } else {
        this.form.filesname = [...allFilesChecked];
      }
      this.uploadFile = true;
    },

    removeFile(data) {
      this.formData.splice(data, 1);
      this.form.filesname.splice(data, 1);
    },

    subString(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },
    checkViewFile(item) {
      console.log("item", item);

      window.open(this.showFileData(item.filename), "_blank");
    },
    showFileData(data) {
      console.log("data", data);
      return `${this.$config.serverURL}/downloads/${data}`;
    },
  },
  watch: {
    isClo() {
      this.applyGetDefaultPayment(this.byProject)
    },
    startDateContributions() {
      this.form.startDateContributions = this.startDateContributions;
      this.form.endDateContributions = mixin.methods
        .formatDate(
          mixin.methods.calculDateInFutur(
            this.startDateContributions,
            this.form.durationCoverage.id
          )
        )
        .split("/")
        .join("-");
    },

    "form.durationCoverage"(newValue) {
      this.form.endDateContributions = mixin.methods
        .formatDate(
          mixin.methods.calculDateInFutur(
            this.startDateContributions,
            newValue.id
          )
        )
        .split("/")
        .join("-");
    },

    item() {
      if (this.item) {
        if (this.item.subscription.subscriber) {
          this.numClient = this.item.subscription.subscriber.clientNumber;
        }

        //this.form.refContrat = this.item.ref;
        this.startDateContributions = this.byProductContent.startDateContributions;

        let x = this.item.quote.aboutProposition.data.find(
          (el) => el.id == this.item.quote.aboutProposition.selectedId
        )

        this.form.durationCoverage = { id: x.corverageDuration, label: x.corverageDuration };
        this.form.cotisationTTC = this.byProductContent.cotisationTTC;

        if(this.item.productName == "ABQ" || this.item.productName == "APF" || this.item.productName == "AAF") {
          this.form.periodicityContributions = "Unique";
        }
        if(this.item.productName == "AHR") {
          this.form.periodicityContributions = this.onPeriodicityOfPayment("monthly")
        }
        if(this.item.productName == "AAE") {
          this.form.periodicityContributions = this.onPeriodicityOfPayment(x.periodicityOfPayment)
        }
      }
    },
    clientAccount() {
      this.form.client = this.clientAccount.id ? this.clientAccount.id : "";
      this.form.username = this.clientAccount.username
        ? this.clientAccount.username
        : "";
    },
    popupAddClient() {
      this.$emit("onDisplayEnabledPolice", !this.popupAddClient);
    },
    variableGlobalFormOject() {
      if (this.formTakeOjet) {
        this.insertTextFormObjet(this.variableGlobalFormOject.id);
      }
    },
    variableGlobalForm() {
      if (this.quillEditorForm.selection) {
        this.insertTextForm(this.variableGlobalForm.id);
      }
    },
  },
};
</script>
